<template>
  <div>
    <div>
      <div>Add a new section.</div>
      <v-text-field
        class="promo-code-input"
        :rules="newSection ? [(v) => !!v || 'Section is required'] : []"
        v-model="newSection"
        label="Section Name"
      ></v-text-field>
      <div class="text-center">
        <v-btn small @click="addSection()" color="primary">Add Section</v-btn>
      </div>
    </div>
    <div class="mt-7 mb-5">
      <div class="my-1">Reorder/Remove</div>

      <div class="d-flex font-weight-bold edit-section-header mt-3">
        <div style="width: 60px" class="mr-5">Order</div>
        <div class="section-name" style="width: 100%">Name</div>
        <div style="width: 40px"></div>
      </div>
      <div v-for="section in seriesSections" :key="section._id">
        <div class="d-flex">
          <div style="width: 60px" class="mr-5">
            <v-text-field
              variant="underlined"
              hide-details
              dense
              :rules="section.order ? [(v) => !!v || 'Order is required'] : []"
              :value="section.order"
              @change="(v) => orderSection(section._id, parseInt(v))"
            >
              <!-- v-model="section.order" -->
            </v-text-field>
          </div>
          <div class="section-name" style="width: 100%">
            <v-text-field
              variant="underlined"
              hide-details
              dense
              :rules="section.name ? [(v) => !!v || 'Name is required'] : []"
              v-model="section.name"
              @change="updateSection(section._id, { name: section.name })"
            >
            </v-text-field>
          </div>
          <div style="width: 40px">
            <v-btn @click="removeSection(section._id)" color="primary" icon small
              ><v-icon>mdi-delete</v-icon></v-btn
            >
          </div>
        </div>
      </div>
    </div>
    <div class="text-center">
      <v-btn color="primary">Save</v-btn>
    </div>
    <v-snackbar v-model="showSaved">
      Saved Section!
      <template v-slot:actions>
        <v-btn variant="text" @click="snackbar = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import ADD_SECTION from "../../graphql/AddSection.gql";
import GET_SECTION from "../../graphql/GetSection.gql";
import UPDATE_SECTION from "../../graphql/UpdateSection.gql";

export default {
  data() {
    return {
      seriesSections: null,
      newSection: null,
      showSaved: false,
    };
  },
  props: {},
  mounted() {
    this.getSeriesSectionList();
  },
  // watch: {
  //   showDialog(newVal) {
  // 	// watch it
  // 	this.bandwidthDialog = newVal;
  //   },
  //   bandwidthDialog(newVal, oldVal) {
  // 	// watch it
  // 	if (newVal !== oldVal) {
  // 	  this.$emit('update:showDialog', newVal);
  // 	}
  //   },
  // },
  methods: {
    async getSeriesSectionList() {
      const getSectionResult = await this.$apollo
        .query({
          query: GET_SECTION,
          fetchPolicy: "no-cache",
          variables: {
            groupId: "default",
            type: "series",
          },
        })
        .catch((error) => {
          this.getLessonError = error.message
            ? error.message.replace("GraphQL error: ", "")
            : "error.";
        });

      this.seriesSections = getSectionResult.data.section;
    },
    async addSection() {
      if (!this.newSection) {
        alert("Missing Section Name");
        return;
      }
      const addedSectionResult = await this.$apollo.mutate({
        mutation: ADD_SECTION,
        variables: {
          sectionName: this.newSection,
        },
      });

      this.getSeriesSectionList();
      this.newSection = null;
      this.addSectionDialog = false;
    },
    removeSection(sectionId) {
      const lessonCount = this.seriesSections.find((section) => section._id === sectionId).lessons
        .length;
      if (lessonCount && lessonCount > 0) {
        alert("Please remove all lessons from this section before deleting it.");
        return;
      }
      if (confirm("Are you sure you want to remove this section?")) {
        this.updateSection(sectionId, { status: "deleted" });
      }
    },
    async orderSection(sectionId, order) {
      // this.seriesSections.sort((a, b) => a.order - b.order);

      const seriesSections = [...this.seriesSections.sort((a, b) => a.order - b.order)];

      const section = Object.assign(
        {},
        seriesSections.find((section) => section._id === sectionId)
      );
      // debugger;
      const sectionIndex = seriesSections.findIndex((section) => section._id === sectionId);

      seriesSections.splice(sectionIndex, 1);

      seriesSections.splice(order - 1, 0, section);

      console.log("seriesSections", seriesSections);

      // let multiPlier = 1;
      for (let index = 0; index < seriesSections.length; index++) {
        const section = seriesSections[index];

        section.order = index + 1;

        console.log(section.name, section.order, order);
        const updateSectionOrderResult = await this.$apollo.mutate({
          mutation: UPDATE_SECTION,
          variables: {
            _id: section._id,
            input: { order: section.order },
          },
        });
        console.log("updateSectionOrderResult", updateSectionOrderResult);
        // }
      }

      this.showSaved = true;
      this.getSeriesSectionList();
    },
    async updateSection(sectionId, section) {
      const updateSectionOrderResult = await this.$apollo.mutate({
        mutation: UPDATE_SECTION,
        variables: {
          _id: sectionId,
          input: section,
        },
      });
      console.log("updateSectionOrderResult", updateSectionOrderResult);
      this.showSaved = true;
      this.getSeriesSectionList();
    },
  },
};
</script>
