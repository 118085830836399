<template>
  <v-container>
    <EditSections />
  </v-container>
</template>

<script>
import EditSections from "../components/admin/EditSections.vue";

export default {
  components: {
    EditSections,
  },
};
</script>
